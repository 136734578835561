export const urlParamsBuilder = (payload: { [key: string]: unknown }): string => {
    const params = new URLSearchParams();
    Object.entries(payload).map(([key, value]) => {
        if (typeof value === 'string') {
            params.append(key, value);
        }

        if (Array.isArray(value) && value.length > 0) {
            value.map((v) => params.append(key, v));
        }

        if (
            value !== null &&
            typeof value === 'object' &&
            !Array.isArray(value) &&
            !Object.values(value).some((v) => v === null)
        ) {
            params.append(key, JSON.stringify(value));
        }
    });
    return params.toString();
};
