import { autoinject } from 'aurelia-framework';
import { Notification, ToastsService } from 'toasts/toasts-service';

@autoinject
export class ToastsContainer {
    notificationService: ToastsService;

    constructor(notificationService: ToastsService) {
        this.notificationService = notificationService;
    }

    getIconCss(level: string) {
        if (level == 'info') {
            return 'text-info';
        } else if (level == 'success') {
            return 'text-success';
        } else if (level == 'warning') {
            return 'text-warning';
        } else {
            return 'text-danger';
        }
    }

    getIconSprite(level: string) {
        if (level == 'info') {
            return 'info-circle-fill';
        } else if (level == 'success') {
            return 'check-circle-fill';
        } else {
            return 'exclamation-circle-fill';
        }
    }

    hide(notification: Notification) {
        this.notificationService.hide(notification);
    }
}
