import { DateTimeFormatValueConverter } from 'datetime-format';

let NotificationLevel = {
    info: 'info',
    success: 'success',
    warning: 'warning',
    danger: 'danger',
};

export class Notification {
    id: number;
    dateTime: Date;
    level: string;
    title: string;
    message: string;
}

export class ToastsService {
    counter: number = 0;
    notifications: Notification[] = [];

    show: boolean = false;

    log(level: string, title: string, message: string, lifespan?: number) {
        let notification = <Notification>{
            id: this.counter++,
            dateTime: new Date(),
            level: level,
            title: title,
            message: message,
        };

        if (typeof lifespan !== 'undefined') {
            setTimeout(() => this.hide(notification), lifespan);
        }

        this.notifications.push(notification);
    }

    info(title: string, message: string, lifespan?: number) {
        this.log(NotificationLevel.info, title, message, lifespan);
    }

    success(title: string, message: string, lifespan?: number) {
        this.log(NotificationLevel.success, title, message, lifespan);
    }

    warning(title: string, message: string, lifespan?: number) {
        this.log(NotificationLevel.warning, title, message, lifespan);
    }

    error(title: string, message: string, lifespan?: number) {
        this.log(NotificationLevel.danger, title, message, lifespan);
    }

    hide(notification: Notification) {
        this.notifications = this.notifications.filter((item) => item.id !== notification.id);
    }
}
