export const urlParamsBuilder = (payload: { [key: string]: unknown }): string => {
    const params = new URLSearchParams();
    Object.entries(payload).map(([key, value]) => {
        const isString = typeof value === 'string' && Boolean(value);
        const isArray = Array.isArray(value) && value.length > 0;
        const isObject =
            value !== null &&
            typeof value === 'object' &&
            !Array.isArray(value) &&
            !Object.values(value).some((v) => v === null);

        if (isString) {
            params.append(key, value);
        }

        if (isArray) {
            value.map((v) => params.append(key, v));
        }

        if (isObject) {
            params.append(key, JSON.stringify(value));
        }
    });
    return params.toString();
};
